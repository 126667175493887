// 创建 svg
function createSVG() {
    $(".svg").each(function () {
        const svg = '<svg class="icon" aria-hidden="true"><use xlink:href="' + $(this).attr("data-icon") + '"></use></svg>';
        $(this).append(svg);
    });
}

function images_swiper() {
    const swiper = new Swiper(".js-image-swiper", {
        slidesPerView: "auto",
        // spaceBetween: 16,
        freeMode: true,
        freeModeSticky: true,
        grabCursor: true,
        nextButton: ".soft-images__next",
        prevButton: ".soft-images__prev",
        watchSlidesProgress: true,
        onProgress: function (swiper, progress) {
            const prev = document.getElementById("js-pattern-prev");
            const next = document.getElementById("js-pattern-next");

            prev.style.display = "";
            next.style.display = "";

            if (progress <= 0) {
                prev.style.display = "none";
            } else if (progress >= 1) {
                next.style.display = "none";
            }

            if (swiper.virtualSize <= swiper.size) {
                prev.style.display = "none";
                next.style.display = "none";
            }
        },
    });
}


function index_swiper() {
    const swiper = new Swiper(".js-index-swiper", {
        loop: true,
        loopAdditionalSlides: 0,
        autoplay: 4000,
        slidesPerView: "auto",
        nextButton: ".index-swiper__next",
        prevButton: ".index-swiper__prev",
        pagination: ".index-swiper__paginations",
    });
}

function recommend_swiper() {
    const swiper = new Swiper(".js-recommend-swiper", {
        slidesPerView: "auto",
        // spaceBetween: 20,
        freeMode: true,
        freeModeSticky: true,
        grabCursor: true,
        nextButton: ".index-recommend__next",
        prevButton: ".index-recommend__prev",
        watchSlidesProgress: true,
        onProgress: function (swiper, progress) {
            const prev = document.getElementById("js-recommend-prev");
            const next = document.getElementById("js-recommend-next");

            prev.style.display = "";
            next.style.display = "";

            if (progress <= 0) {
                prev.style.display = "none";
            } else if (progress >= 1) {
                next.style.display = "none";
            }

            if (swiper.virtualSize <= swiper.size) {
                prev.style.display = "none";
                next.style.display = "none";
            }
        },
    });
}



function new_swiper(container_element_class) {

    if (!$(container_element_class)[0]) { return }
    const oldSwiper = $(container_element_class)[0].swiper
    let realIndex = 0;

    if (oldSwiper) {

        realIndex = oldSwiper.realIndex || 0;
        oldSwiper.destroy()

    }




    const swiper = new Swiper(container_element_class, {
        slidesPerView: "auto",
        // spaceBetween: 20,
        freeMode: true,
        freeModeSticky: true,
        grabCursor: true,
        nextButton: container_element_class + " .next_button",
        prevButton: container_element_class + " .prev_button",
        watchSlidesProgress: true,
        onProgress: function (swiper, progress) {
            const prev = $(container_element_class + " .prev_button");
            const next = $(container_element_class + " .next_button");

            prev.css("display", "");
            next.css("display", "");

            if (progress <= 0) {
                prev.css("display", "none");
            } else if (progress >= 1) {
                next.css("display", "none");
            }

            if (swiper.virtualSize <= swiper.size) {
                prev.css("display", "none");
                next.css("display", "none");
            }
        },
    });

    swiper.slideTo(realIndex, 0, false)
}

function sub_swiper() {
    const swiper = new Swiper(".js-sub-swiper", {
        loop: true,
        loopAdditionalSlides: 0,
        autoplay: 4000,
        slidesPerView: "auto",
        nextButton: ".index-sub__next",
        prevButton: ".index-sub__prev"
    });
}


function show(id) {
    $(id).addClass("show");
    $(id).removeClass("hide");
}

function hide(id) {
    $(id).addClass("hide");
    $(id).removeClass("show");
}


// 图片占位图、加载失败
function img_failed() {
    $(".box img").error(function () {
        $(this).replaceWith("<div class='img-failed'></div>");
        $('.box div[class~="img-failed"]')
            .parent()
            .removeClass("img-placeholder");
    });

    $('.box img[class~="lazyload"]').parent().addClass("img-placeholder");

    $(".box img").each(function () {
        var observer = new MutationObserver(function (mutationsList, ob) {
            for (let mutation of mutationsList) {
                if (mutation.type !== "attributes") {
                    return;
                }

                $('.box img[class~="lazyloaded"]')
                    .parent()
                    .removeClass("img-placeholder");
            }
        });
        observer.observe(this, {
            attributes: true,
            attributeFilter: ["class"],
        });
    });
}


// 弹出层切换
function menunav(id) {
    if ($(id).attr("data-show") == "1") {
        $(id).css("display", "none");
        $(id).attr("data-show", "0");
    } else {
        $(id).css("display", "block");
        $(id).attr("data-show", "1");
    }
}

// 切换锚点
export function toggle_anchor(id) {
    if (location.hash === "#" + id) {
        location.hash = "";
    } else {
        location.hash = id;
    }
}


// import { lightbox } from "lightbox2";
const lightbox = require("lightbox2");
export function main() {
    createSVG();
    images_swiper();
    index_swiper();
    sub_swiper();
    img_failed();
    drawcanvas();
    recommend_swiper();
    new_swiper(".full-softpart1");
    new_swiper(".full-softpart2");
    new_swiper(".full-softpart3");
    new_swiper(".full-softpart4");
    new_swiper(".full-softpart5");
    new_swiper(".full-newspart1");
    new_swiper(".main-newspart1");
    new_swiper(".main-softpart2");
    new_swiper(".main-softpart3");
    new_swiper(".softlist-recommend");
    new_swiper(".softlist-sort");

    // 粘合侧边，修改顶部定位位置
    $(document).ready(function () {
        $('.main, .aside')
            .theiaStickySidebar({
                additionalMarginTop: 0,
            });
    });

    // 文章详情图片预览，增加a标签
    $(".article-content img").each(function (index, element) {
        const $link = $("<a class='image' href=" + $(element).attr("src") + " data-lightbox='article-image'></a>")
        $link.append($(element).clone(true, true))
        $(element).replaceWith($link)
    })

    // 默认图片样式
    $(".article-content img").css({ "width": "auto", "height": "auto" });


    // 图片预览,参数修改
    lightbox.option({
        resizeDuration: 450,
        wrapAround: true,
        albumLabel: " %1 / %2",
        wrapAround: true,
    });
 

    //  排行榜切换
    $(".tfBox")
        .find("li")
        .hover(function () {
            $(this)
                .find(".tBox")
                .removeClass("hide")
                .siblings(".fBox")
                .addClass("hide");
            $(this)
                .siblings()
                .find(".tBox")
                .addClass("hide")
                .siblings(".fBox")
                .removeClass("hide");
        });

}

function drawcanvas() {
    $('.circular').each(function (index, el) {
        var p = $(el).attr('data-percent');
        var angle = Math.PI * 2 * (p / 100) - Math.PI / 2;
        var cvs = $(el).find('.cvs');
        var cxt = cvs[0].getContext("2d");

        cxt.lineWidth = 40;
        cxt.strokeStyle = "#39C059";
        cxt.beginPath();
        cxt.arc(200, 200, 180, -Math.PI / 2, angle, false);//顺时针，默认从3点钟方向开始
        cxt.stroke();//画空心圆
    })
}



